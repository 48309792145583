.container{
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 25;
    padding: 40;
}

.chart{
    width: 800px;
}

@media screen and (max-width:1188px) {

}

@media screen and (max-width:848px) {
  

}


@media screen and (max-width:555px) {
   

}